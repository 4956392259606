<template>
  <div>
    <div class="filters" id="filters">
      <div class="mobile-filters container">
        <p><span v-html="items.length"></span>&nbsp;risultati trovati.</p>
        <button v-if="showFilters==false" @click="showFilters=true" class="btn btn-filter">Filtra <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M1 0h22l-9 15.094v8.906l-4-3v-5.906z"/></svg></button>
        <button v-if="showFilters==true" @click="showFilters=false" class="btn btn-filter">Chiudi <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg></button>
      </div>
      <div :class="{'hide':showFilters==false}" class="container filters-form">
        <form>
          <div class="row g-3">
            <div class="col-sm-4">
              <v-select :disabled="loading" :loading="nicchie.length < 1" :searchable="false" v-model="selected.nicchia" :options="nicchie" :multiple="false" :placeholder="'- Scegli la nicchia -'">
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
            <div class="col-sm-4">
              <v-select :disabled="loading || !selected.nicchia" :loading="followers.length < 1" :searchable="false" v-model="selected.follower" :options="followers" :multiple="false" :placeholder="'- Scegli il range di follower -'">
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
            <div class="col-sm-4">
              <v-select :disabled="loading || !selected.nicchia" :loading="collaborazioni.length < 1" :searchable="false" v-model="selected.collaborazione" :options="collaborazioni" :multiple="false" :placeholder="'- Scegli il tipo di collaborazione -'">
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
            <div class="col-sm-12 mastermind">
              <switches v-model="selected.mastermind"></switches>
              Mostra solo utenti disponibili a realizzare un mastermind
            </div>
          </div>
        </form>
      </div>
    </div>

    <section class="management">
      <div class="container title" id="section-title">
        <h3>Ecco i risultati della tua ricerca</h3>
        <div class="view-toggle">
          <button :class="{'active': view == 'grid'}" @click="view='grid'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 6h-6v-6h6v6zm9-6h-6v6h6v-6zm9 0h-6v6h6v-6zm-18 9h-6v6h6v-6zm9 0h-6v6h6v-6zm9 0h-6v6h6v-6zm-18 9h-6v6h6v-6zm9 0h-6v6h6v-6zm9 0h-6v6h6v-6z"/></svg></button>
          <button :class="{'active': view == 'map'}" @click="view='map'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm12 16l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 4-9 3.96-1.584c.38.516.741 1.08 1.061 1.729l-3.523 1.41-1.725 3.88 2.672-1.01 1.506-2.687-.635 3.044 4.189 1.789.495-2.021.465 2.024 4.15-1.89-.618-3.033 1.572 2.896 2.732.989-1.739-3.978-3.581-1.415c.319-.65.681-1.215 1.062-1.731l4.021 1.588 3.936 9z"/></svg></button>
        </div>
      </div>
      <div class="view-map" v-if="view == 'map'">
        <ViewMap @openProfile="openProfile" :entities="items"></ViewMap>
      </div>
      <div class="view-grid" v-if="view == 'grid'">
        <section class="container">
          <div class="loading" v-if="loading == true">
            <p>Ricerca in corso...</p>
          </div>

          <template v-else>
            <div class="list-elements no-elements mb-5" v-if="offItems.length === 0">
              <h3>Nessun elemento trovato</h3>
              <p>Prova a modificare i parametri di ricerca</p>
            </div>
            <div class="list-elements off-elements mb-5" v-if="offItems.length > 0">
              
              <div class="items row row-cols-1 row-cols-xxl-6 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 g-4">
                <div class="col" v-for="item in offItems" :key="item.nickname">
                  <profile-card @openProfile="openProfile(item)" @toggleFavs="toggleFavs(item)" :item="item"></profile-card>
                </div>
              </div>
              <div class="pagination">
                <router-link :to="'/finder/' + i" :class="{'current': i == page}" v-for="i in this.pages" @click="offset = (i-1) * limit" :key="i">{{i}}</router-link>
              </div>
            </div>
            <div class="list-elements alt-elements" v-if="altItems.length > 0 && offItems.length < 3">
              <h3>Potrebbero interessarti</h3>
              <div class="items row row-cols-1 row-cols-lg-6 row-cols-md-4 row-cols-sm-2 g-4">
                <div class="col" v-for="item in altItems" :key="item.nickname">
                  <profile-card @openProfile="openProfile(item)" :item="item"></profile-card>
                </div>
              </div>
            </div>
          </template>
        </section>
      </div>
    </section>

    <transition name="fade">
      <profile v-if="profile" @close="profile = null" :profile=profile></profile>
    </transition>

    <div v-if="noPlace" class="cm-toast">
      <p>Aggiorna il tuo profilo, inserisci la tua città e scopri gli altri masters vicini a te!</p>
      <button class="btn btn-link" @click="hideToast">Non mostrare più</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import vSelect from 'vue-select'
import Switches from 'vue-switches'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css'

import ViewMap from '@/components/ViewMap.vue'
import Profile from '@/components/Profile.vue'
import ProfileCard from '@/components/ProfileCard.vue'

export default {
  components: {
    ViewMap,
    Profile,
    ProfileCard,
    Switches
  },
  props: {
    page: {
      default: '1',
      required: true,
      type: String
    }
  },
  data () {
    return {
      limit: 18,
      offset: 0,
      collaborazioni: [],
      profile: null,
      followers: [],
      loading: false,
      noPlace: false,
      nicchie: [],
      offItems: [],
      altItems: [],
      items: [],
      selected: {
        nicchia: '',
        follower: '',
        collaborazione: '',
        mastermind: false
      },
      pages: 0,
      view: 'grid',
      showFilters: false
    }
  },

  methods: {
    hideToast () {
      this.noPlace = false

      let profile = this.$store.getters.getProfile
      const params = new URLSearchParams();

      Object.keys(profile).forEach(key => {
        if (key == 'lonlat') {
          params.append(key, '0,0')
        }
        else {
          params.append(key, profile[key])
        }
      });

      params.append('action', 'updateUser2');
      params.append('lonlat', '0,0');
      params.append('uuid', this.$store.getters.getUser);

      axios.post(process.env.VUE_APP_API, params).then(response => {
        this.$store.commit('setProfile', response.data.data)
      })
    },
    openProfile (item) {
      this.profile = item
    },
    getItems () {
      this.items = []
      this.offItems = []
      this.altItems = []
      this.loading = true

      let params = {
        action: 'readFilter2',
        limit: 999999, // this.limit
        offset: this.offset,
        uuid: this.$store.getters.getUser
      }
      if (this.selected.nicchia) {
        params.nicchia = this.selected.nicchia
      }
      if (this.selected.follower) {
        params.follower = this.selected.follower
      }
      if (this.selected.collaborazione) {
        params.collaborazione = this.selected.collaborazione
      }
      if (this.selected.mastermind) {
        params.mastermind = this.selected.mastermind
      }

      axios.get(process.env.VUE_APP_API, {
        params: params
      }).then(response => {
        this.loading = false
        if (response.data.errors) {
          this.$store.commit('setAlert', {
            type: 'danger',
            messages: response.data.errors
          })
        }
        else {
          const result = response.data.data.elements.reduce((resultArray, item, index) => { 
            const chunkIndex = Math.floor(index/this.limit)
            if(!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [] // start a new chunk
            }
            resultArray[chunkIndex].push(item)
            return resultArray
          }, [])

          this.items = response.data.data.elements
          this.chuckItems = result
          this.offItems = this.chuckItems[0]
          this.altItems = response.data.data.partial ? response.data.data.partial : []

          this.pages = Math.ceil(response.data.data.count / this.limit)
        }
      })
    }
  },

  mounted () {
    let profile = this.$store.getters.getProfile
    if (profile.completed && (profile.lonlat == null || profile.lonlat == '')) {
      this.noPlace = true
    }

    axios.get(process.env.VUE_APP_API, {
      params: {
        action: 'readFollower'
      }
    }).then(response => {
      if (response.data.errors) {
        this.$store.commit('setAlert', {
          type: 'danger',
          messages: response.data.errors
        })
      }
      else {
        this.followers = response.data.data
      }
    })

    axios.get(process.env.VUE_APP_API, {
      params: {
        action: 'readCollabo'
      }
    }).then(response => {
      if (response.data.errors) {
        this.$store.commit('setAlert', {
          type: 'danger',
          messages: response.data.errors
        })
      }
      else {
        this.collaborazioni = response.data.data
      }
    })

    axios.get(process.env.VUE_APP_API, {
      params: {
        action: 'readNicchie'
      }
    }).then(response => {
      if (response.data.errors) {
        this.$store.commit('setAlert', {
          type: 'danger',
          messages: response.data.errors
        })
      }
      else {
        this.nicchie = response.data.data.filter(el => el[1] === true).map(el => el[0]).sort()
      }
    })
    
  },
  watch: {
    selected: {
      handler: function (val) {
        if (!val.nicchia) {
          this.selected.follower = ''
          this.selected.collaborazione = ''
        }

        if (this.page !== '1') {
          this.$router.push({
            name: 'finder',
            params: {
              nicchia: this.selected.nicchia,
              follower: this.selected.follower,
              collaborazione: this.selected.collaborazione
            }
          })
        }
        else {
          this.getItems()
        }
      },
      immediate: true,
      deep: true
    },
    page: {
      handler: function () {
        this.offset = (this.page - 1) * this.limit
        this.offItems = this.chuckItems[this.page - 1]
      },
      // immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.filters {
  background: $background-color;
  padding-bottom: 2rem;
  .mobile-filters {
    display: flex;
    vertical-align: middle;
    p {
      display: flex;
      align-items: center; 
      margin: 0;
      padding: 0;
      vertical-align: middle;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  .btn-filter {
    color: #5e5e57 !important;
    float: right;
    margin: 0 0 0 auto;
    padding: 0;
    svg {
      display: inline-block;
      fill: #5e5e57;
      height: 18px;
      width: 18px;
    }
  }
  .filters-form {
    margin-top: 15px;
    &.hide {
      display: none;
    }

    @media screen and (min-width: 768px) {
      display: block!important;
      margin-top: 0;
    }
  }
  .v-select {
    ::v-deep {
      .vs__dropdown-toggle {
        background: white;
        border: 1px solid #ced4da;
      }
    }
    &.vs--disabled {
      ::v-deep {
        .vs__dropdown-toggle {
          background: #e9ecef;
          input {
            background: #e9ecef;
          }
          .vs__open-indicator, .vs__clear {
            background: none;
          }
          .vs__clear {
            svg {
              margin-top: -3px
            }
          }
        }
      }
    }
  }
}

.loading {
  font-size: 1.25rem;
  font-style: italic;
  padding: 4rem;
  text-align: center;
}

.view-grid {
  .list-elements {
    h3 {
      margin-bottom: 2rem;
    }
    .items {
      button, a.btn {
        background: $primary-color;
        border: none;
        color: white;
        text-decoration: none;
        width: 100%;
      }
    }
    .pagination {
      margin-top: 2rem;
      justify-content: center;
      a {
        // background: $primary-color;
        border: none;
        color: $primary-color;
        margin: 0 .25rem;
        padding: 4px 8px;
        text-decoration: none;
        &:hover, &.current {
          background: $primary-color;
          color: white
        }
      }
    }
  }
}

.mastermind {
  vertical-align: middle;
  label {
    vertical-align: middle;
  }
  ::v-deep {
    .vue-switcher-theme--default.vue-switcher-color--default div {
      background-color: #99AB68;
    }
    .vue-switcher-theme--default.vue-switcher-color--default div:after {
      background-color: #a6b18b;
    }
    .vue-switcher-theme--default.vue-switcher-color--default.vue-switcher--unchecked div {
      background-color: #aaa;
    }
    .vue-switcher-theme--default.vue-switcher-color--default.vue-switcher--unchecked div:after {
      background-color: #c4c4c4;
    }
  }
}

.management {
  .title {
    display: flex;
    padding-bottom: 2rem;
    padding-top: 2rem;
    .view-toggle {
      margin-left: auto;
      margin-right: 0;
      button {
        border: 1px solid #99AB68;
        padding: 8px;
        svg {
          display: block;
          fill: #99AB68;
          height: 16px;
          margin: 0;
          padding: 0;
          width: 16px;
        }
        &.active {
          background: #99AB68;
          svg {
            fill: white
          }
        }
      }
    }
  }
  .view-grid {
  }
  .view-map {
  }
}
</style>