<template>
  <div class="input-place">
    <v-select :loading="loading" :options="options" :placeholder="'- Scegli la tua città -'" :value="input_value" @search="searchPlace" @input="selectPlace"/>
    <div v-if="item.error" class="invalid-feedback">
      {{item.error}}
    </div>
    <div class="form-text" v-if="item.description">
      {{item.description}}
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  'name': 'InputPlace',
  components: {
    vSelect
  },
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      input_value: null,
      loading: false,
      options: []
    }
  },
  mounted() {
    this.input_value = this.item.value
  },
  methods: {
    searchPlace: function(search, loading){
      if (search.length > 2) {
        loading(true);
        fetch('https://nominatim.openstreetmap.org/search?accept-language=it&format=jsonv2&city=' + search).then(response => {
          return response.json();
        }).then((json) => {
          this.options = json.map(el => el.display_name)
          loading(false);
        })
      }
    },
    selectPlace: function(val) {
      this.input_value = val
      fetch('https://nominatim.openstreetmap.org/search?accept-language=it&format=jsonv2&q=' + val).then(response => {
        return response.json();
      }).then((json) => {
        let coords = json.map(el => el)
        this.$emit('change', {'value': val, 'hidden': [coords[0]['lon'], coords[0]['lat']]})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
    background: #fff;
    border-radius: 0.25rem;
    ::v-deep {
      .vs__dropdown-toggle {
        border: 1px solid #ced4da;
        padding: 0.375rem 0.75rem;
        .vs__search {
          margin: 0;
        }
      }
      .vs__selected {
        margin: 0;
      }
      .vs__actions {
        padding: 0;
      }
      .vs__selected-options {
        position: static;
      }
      .vs__dropdown-menu {
        max-height: 210px!important;
      }
    }
  }
</style>