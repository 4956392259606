<template>
  <div class="home">
    <div class="panel login" id="login">
      <div class="panel-bg">
        <header class="center">
          <logo></logo>
        </header>
        
        <send-reset-form @change="changeForm"></send-reset-form>

      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import SendResetForm from '@/components/SendResetForm.vue'

export default {
  name: 'Home',
  data() {
    return {
      form: 'login'
    }
  },
  components: {
    Logo,
    SendResetForm
  }
}
</script>


<style lang="scss" scoped>
.home {
  height: 100%;

  .panel {
    flex-direction: column;
    display: flex;
    height: 100%;
    position: relative;

    &#login {
      background: linear-gradient(180deg, $background-color 55%, white 40%);
      position: relative;

      .panel-bg {
        background: url('/imgs/login.png') no-repeat center top;
        background-size: auto 55%;
        min-height: 100%;

        header {
          margin-bottom: auto;
          margin-top: 2rem;
          h1 {
            color: white;
          }
        }
      }      
    }
  }

  .go-down {
    bottom: 1rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    svg {
      transform: rotate(90deg);
      fill: $primary-color;
    }
  }
}
</style>