<template>
  <div class="finder">
    <section>
      <div class="content">
        <div id="map">
        </div>
      </div>
      <div v-if="showGrid" class="grid">
        <div class="cnt-close" @click="showGrid=false"></div>
        <div class="container">
          <div class="buttons">
            <button class="btn-close" @click="showGrid=false"></button>
          </div>
          <div class="profile-list">
            <div class="cnt-close" @click="showGrid=false"></div>
            <div class="items row row-cols-1 row-cols-xxl-6 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 g-4">
              <div class="col" v-for="item in features" :key="item.nickname">
                <profile-card :item="item" @openProfile="openProfile(item)"></profile-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Map from 'ol/Map.js';
import OSM from 'ol/source/OSM.js';
import TileLayer from 'ol/layer/Tile.js';
import View from 'ol/View.js'
import "ol/ol.css";
import VectorLayer from 'ol/layer/Vector.js';
import {Cluster, Vector as VectorSource} from 'ol/source';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text
} from 'ol/style';
import { fromLonLat, transform } from 'ol/proj';

import ProfileCard from '@/components/ProfileCard.vue'

export default {
  name: 'ViewMap',
  components: {
    ProfileCard
  },
  props: {
    entities: {
      type: Array,
      default: null,
      required: true
    }
  },
  data () {
    return {
      clusters: null,
      clusterSource: null,
      features: [],
      loading: false,
      map: null,
      raster: null,
      source: null,
      showGrid: false
    }
  },
  mounted() {
    let h = window.innerHeight
    let el1 = document.getElementById('section-title').offsetHeight
    let el2 = document.getElementById('filters').offsetHeight
    let el3 = document.getElementById('logo').offsetHeight
    let el4 = document.getElementById('subheader').offsetHeight
    const box = document.getElementById('map');
    box.style.height = h - el1 - el2 - el3 - el4 + 'px';

    const raster = new TileLayer({
      source: new OSM(),
    });

    this.map = new Map({
      layers: [raster],
      target: 'map',
      view: new View({
        center: [12.5674, 41.8719],
        zoom: 6,
      }),
    });

    this.map.updateSize()
    this.map.getView().setCenter(transform([12, 41.627671], 'EPSG:4326', 'EPSG:3857'));
    this.map.getView().setZoom(5);

    this.populateMap(this.entities)

    this.map.on('click', (e) => {
      this.clusters.getFeatures(e.pixel).then((clickedFeatures) => {
        if (clickedFeatures.length) {
          // Get clustered Coordinates
          const features = clickedFeatures[0].get('features');
          if (features.length >= 1) {
            this.showGrid = true;
            this.features = features.map(el => el.get('profile'));
          }
        }
      });
    });
  },
  watch: {
    entities: {
      handler: function (entities) {
        this.populateMap(entities)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    openProfile(item) {
      this.$emit('openProfile', item);
    },
    formatFeatures(entities, callback) {
      let features = entities.filter(el => el.lonlat !== '' && el.lonlat !== '0,0').map(el => {
        let feat = new Feature({
          'geometry': new Point(fromLonLat(el.lonlat.split(','))),
          'uid': el.uuid,
          'profile': el
        })
        return feat
      })
      return callback(features)
    },
    populateMap(entities) {
      this.formatFeatures(entities, (records) => {
        if (this.map) {
          if (this.clusters) {
            this.map.removeLayer(this.clusters)
          }

          this.source = new VectorSource({
            features: records,
          });

          this.clusterSource = new Cluster({
            distance: 10,
            minDistance: 10,
            source: this.source,
          });

          const styleCache = {};
          this.clusters = new VectorLayer({
            source: this.clusterSource,
            style: function (feature) {
              const size = feature.get('features').length;
              let style = styleCache[size];
              if (!style) {
                style = new Style({
                  image: new CircleStyle({
                    radius: 10,
                    stroke: new Stroke({
                      color: '#fff',
                    }),
                    fill: new Fill({
                      color: '#98a76d',
                    }),
                  }),
                  text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                      color: '#fff',
                    }),
                  }),
                });
                styleCache[size] = style;
              }
              return style;
            },
          });

          this.map.addLayer(this.clusters)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.finder {
  background: $secondary-color;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

#map {
  height: 100%;
  position: sticky;
  width: 100%;
}

.finder {
  display: flex;
  flex-direction: column;
  section {
    flex: 1;
    position: relative;
    .content {
      height: 100%,
    }
    .grid {
      background: rgba(246,246,246,0.8);
      height: 100%;
      left: 0;
      overflow-y: scroll;
      padding: 20px;
      position: absolute;
      top: 0;
      width: 100%;
      .container {
        position: relative;
        .buttons {
          text-align: right;
          .btn-close {
            margin: 0 0 20px;
          }
        }
      }
      .profile-list {
        // background: #ffffff;
      }
      .cnt-close {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}


</style>