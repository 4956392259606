<template>
  <div>
    <input-place :item="{'type': 'text', 'name': 'place', 'description': 'demo', 'value': 'Milano'}" @change="watch"></input-place>
  </div>
</template>

<script>
import '@/components/input/InputPlace';
import InputPlace from '../components/input/InputPlace.vue';
export default {
  components: { InputPlace },
  methods: {
    watch: () => {
    }
  }
}
</script>

<style lang="scss" scoped>

</style>