<template>
  <div class="finder">
    <div class="subheader" id="subheader">
      <router-link to="/account">Modifica profilo</router-link>
      - 
      <a @click="deleteUser">Cancellati</a>
      - 
      <a @click="logout">Logout</a>
    </div>
    <header class="center" id="logo">
      <logo></logo>
    </header>

    <section>
      <div class="content">
        <manager :page=page></manager>
      </div>
    </section>

    <footer>
      
    </footer>
  </div>
</template>

<script>
import axios from 'axios';

import Logo from '@/components/Logo.vue'
import manager from '@/components/Manager.vue'

export default {
  name: 'Finder',
  components: {
    Logo,
    manager
  },
  props: {
    page: {
      type: String,
      default: '1',
      required: false
    }
  },
  data () {
    return {
      message: 'Welcome to Your Vue.js App'
    }
  },
  
  beforeCreate() {
    let user = this.$store.getters.getUser
    if (!user) {
      this.$router.push('/')
    }
  },

  methods: {
    deleteUser: function () {
      let sicurezza = confirm('Sicuro di voler eliminare il tuo account?')
      if (sicurezza) {
        const params = new URLSearchParams();
        params.append('action', 'deleteUser');
        params.append('uuid', this.$store.getters.getUser);

        axios.post(process.env.VUE_APP_API, params).then(() => {
          this.$store.commit('logout', null)
          this.$router.push('/')
        })
      }
    },
    logout: function () {
      this.$store.commit('logout', null)
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.finder {
  background: $secondary-color;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  .subheader {
    background: darken($color: $background-color, $amount: 20%);
    padding: 0 15px;
    text-align: right;
    text-decoration: none;
    a {
      color: white;
      cursor: pointer;
      text-decoration: none;
      font-size: .85rem;
    }
  }

  header {
    background: $background-color;
    padding: 2rem 0 2rem;
  }
}
</style>