<template>
  <div class="home">
    <div class="panel" id="come-funziona">
      <router-link to="/" class="go-top">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z"/></svg>
      </router-link>
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <h2>Come funziona?</h2>
              <p>Per crescere su Instagram sono fondamentali solo 2 elementi: che ci sia traffico verso il nostro profilo e che questo traffico venga convertito in follower.</p>
              <p>Uno dei modi più efficaci in assoluto per portare traffico sul nostro profilo Instagram, e quindi crescere, è collaborare con altri profili. Per aiutarti in questo io e il mio team abbiamo creato una piattaforma unica nel suo genere: AMAZING COMMUNITY CLUB. Ti basterà registrarti nella piattaforma per iniziare a farti conoscere, ma soprattutto ti servirà per trovare altri studenti con i quali collaborare e quindi crescere insieme su Instagram.</p>
            </div>
            <div class="col-md-4 col-8 offset-lg-1 offset-md-8 offset-4">
              <img class="img-fluid" src="/imgs/home.png">
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <p>&copy; Grace The Amazing, Onice Srl - Via Cascina Prada 4/A 21022 Azzate (VA) Italia, P.IVA: 03873990125</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      form: 'login'
    }
  },
  components: {
  },
  methods: {
    changeForm(form) {
      this.form = form
    }
  },
  watch: {
    form() {
    }
  },
}
</script>


<style lang="scss" scoped>
.content {
  height: 100%;
  display: flex;
  align-items: center;
    
  .container {
    .row {
      h2 {
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

.home {
  height: 100%;
  overflow: auto;

  .panel {
    flex-direction: column;
    display: flex;
    height: 100%;
    position: relative;

    &#come-funziona {
      // height: auto;
      @media screen and (max-width: 767px) {
        height: auto;
        min-height: 100%;
        padding-top: 60px;
      }
      .content {
        padding: 2rem 0;
      }
    }
  }
}

footer {
  background: $background-color;
  margin-top: auto;
  p {
    color: white;
    margin: 0;
    padding: 2rem 0;
  }
}

.go-top {
  top: 1rem;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  z-index: 9;
  svg {
    transform: rotate(-90deg);
    fill: $primary-color;
  }

  @media screen and (max-width: 767px) {
    display: inline-block;
    margin-top: 1rem;
  }
}
</style>