<template>

  <form ref="accountForm" id="account-form" class="needs-validation" :class="form_class" novalidate @keyup.enter="submit"> 
    <fieldset class="step">
      <div class="form-panel">
        <h1 v-if="step.title">{{step.title}}</h1>
        <h2 v-if="step.subtitle">{{step.subtitle}}</h2>
        <template v-if="step.fields">
          <div v-for="(field, key) in step.fields" :key="key" class="form-group" :class="'form-group-' + field.type">
            <label class="form-label mb-3" :for="field.name">{{field.label}}</label>
            <div class="input--type input--image mb-5" v-if="field.type == 'file'">
              <div class="images">
                <div
                  class="imagePreviewWrapper"
                  :class="{hasImage: field.value !== null}"
                  :style="{ 'background-image': `url(${field.value})` }"
                  @click="selectImage">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                </div>
              </div>
              <input
                ref="fileInput"
                type="file"
                :required="field.value == null"
                :accept="field.accept"
                @change="pickFile(key)">
              <div v-if="field.error" class="invalid-feedback">
                {{field.error}}
              </div>
            </div>
            <div class="input--type input--select mb-5" v-else-if="field.type == 'select'">
              <v-select :loading="options[field.name].length == 0" :searchable="false" v-model="field.value" :options="options[field.name]" :multiple="field.multiple" :selectable="() => field.value.length < field.max">
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="field.value.length == 0"
                    v-bind="attributes"
                    v-on="events"
                  />
                  <div v-if="field.error" class="invalid-feedback">
                    {{field.error}}
                  </div>
                </template>
              </v-select>
            </div>
            <div class="input--type input--radio mb-5" v-else-if="field.type == 'radio'">
              <switches v-model="field.value"></switches>
              <span v-if="field.value == true">Si</span>
              <span v-else>No</span>
            </div>
            <div class="input--type input--radio mb-5" v-else-if="field.name == 'citta'">
              <input-place :item="field" @change="setValue"></input-place>
            </div>
            <div class="input--type input--radio mb-5" v-else-if="field.type == 'hidden'">
              <input v-model="field.value" class="form-check form-control" required minlength="3" maxlength="255" :type="field.type" :name="field.name" :id="field.name">
            </div>
            <div class="input--type input--text mb-5" v-else>
              <input v-model="field.value" class="form-check form-control" required minlength="3" maxlength="255" :type="field.type" :name="field.name" :id="field.name">
              <div v-if="field.error" class="invalid-feedback">
                {{field.error}}
              </div>
              <div class="form-text" v-if="field.description">
                {{field.description}}
              </div>
            </div>
          </div>
        </template>
        
        
        <div v-if="loading" class="loading">
          <p>Salvataggio in corso...</p>
        </div>
        <div v-else class="form-group button-group mt-3">
          <button type="submit" class="btn btn-primary next-step" @click="nextStep">{{step.button}}</button>
          <router-link to="finder" v-if="alreadyComplete && stepCurrent === 1" type="submit" class="btn btn-link prev-step">Indietro</router-link>
          <a type="submit" class="btn btn-link prev-step" v-else-if="stepCurrent > 0" @click="prevStep">Indietro</a>
        </div>
      </div>
    </fieldset>
  </form>

</template>

<script>
import axios from 'axios';
import Vue from 'vue'
import vSelect from 'vue-select'
import Switches from 'vue-switches'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css'

import '@/components/input/InputPlace';
import InputPlace from './input/InputPlace.vue';

export default {
  components: {
    InputPlace,
    Switches
  },
  data () {
    return {
      form_class: null,
      stepCurrent: 0,
      previewImage: null,
      loading: false,
      steps: [
        {
          title: 'Benvenuto/a!',
          subtitle: 'Completa il tuo profilo.',
          button: 'Inizia!'
        },
        {
          button: 'Prosegui',
          fields: [
            {
              error: 'Inserisci un valore valido.',
              name: 'nome',
              label: 'Nome *',
              type: 'text',
              required: true,
              minlength: 3,
              maxlength: 255,
              value: null
            }
          ]
        },
        {
          button: 'Prosegui',
          fields: [
            {
              error: 'Inserisci un valore valido.',
              name: 'cognome',
              label: 'Cognome *',
              type: 'text',
              required: true,
              minlength: 3,
              maxlength: 255,
              value: null
            }
          ]
        },
        {
          button: 'Prosegui',
          fields: [
          {
              error: 'Inserisci un valore valido.',
              name: 'citta',
              label: 'Città *',
              type: 'text',
              required: true,
              minlength: 3,
              maxlength: 255,
              value: null
            },
            {
              error: 'Inserisci un valore valido.',
              name: 'lonlat',
              type: 'hidden',
              required: true,
              minlength: 3,
              maxlength: 255,
              value: '0,0'
            }
          ]
        },
        {
          button: 'Prosegui',
          fields: [
            {
              error: 'Inserisci un valore valido.',
              name: 'immagine',
              label: 'Immagine profilo Instagram *',
              type: 'file',
              value: null,
              required: true,
              accept: '.png,.jpg,.jpeg'
            }
          ]
        },
        {
          button: 'Prosegui',
          fields: [
            {
              error: 'Inserisci un valore valido.',
              name: 'nickname',
              label: 'Nickname Instagram *',
              type: 'text',
              value: null,
              required: true,
              minlength: 3,
              maxlength: 255,
              description: 'Senza @ e tutto attaccato.'
            }
          ]
        },
        {
          button: 'Prosegui',
          fields: [
            {
              error: 'Inserisci un valore valido.',
              name: 'follower',
              label: 'Numero di follower (max 1)*',
              type: 'select',
              multiple: true,
              required: true,
              value: [],
              max: 1,
              options: []
            }
          ]
        },
        {
          button: 'Prosegui',
          fields: [
            {
              error: 'Inserisci un valore valido.',
              name: 'nicchia',
              label: 'Nicchia (max 3)*',
              type: 'select',
              required: true,
              multiple: true,
              value: [],
              max: 3
            }
          ]
        },
        {
          button: 'Prosegui',
          fields: [
            {
              error: 'Inserisci un valore valido.',
              name: 'collaborazione',
              label: 'Che tipo di collaborazione sei disposto/a a fare? (max 3)*',
              type: 'select',
              required: true,
              multiple: true,
              max: 3,
              value: []
            }
          ]
        },
        {
          button: 'Prosegui',
          fields: [
            {
              name: 'presentazione',
              label: 'Presentazione libera',
              type: 'textarea',
              value: null
            }
          ]
        },
        {
          button: 'Prosegui',
          fields: [
            {
              name: 'mastermind',
              label: 'Sei disponibile ad organizzare un mastermind?',
              type: 'radio',
              value: false
            }
          ]
        }
      ],
      options: {
        follower: [],
        nicchia: [],
        collaborazione: []
      }
    }
  },
  computed: {
    step () {
      return this.steps[this.stepCurrent]
    },
    profile () {
      return this.$store.getters.getProfile
    },
    alreadyComplete () {
      return this.profile.completed
    }
  },
  mounted () {
    if (this.profile.nome) {
      this.stepCurrent = 1

      this.steps[1].fields[0].value = this.profile.nome
      this.steps[2].fields[0].value = this.profile.cognome
      this.steps[3].fields[0].value = this.profile.citta
      this.steps[3].fields[1].value = this.profile.lonlat
      this.steps[4].fields[0].value = this.profile.immagine
      this.steps[5].fields[0].value = this.profile.nickname
      this.steps[6].fields[0].value = this.profile.follower
      this.steps[7].fields[0].value = this.profile.nicchia
      this.steps[8].fields[0].value = this.profile.collaborazione
      this.steps[9].fields[0].value = this.profile.presentazione
      this.steps[10].fields[0].value = this.profile.mastermind

      // this.getDataUrl(this.profile.immagine, (e) => {
      //   this.steps[3].fields[0].value = e
      // })
    }
  },
  methods: {
    setValue (el) {
      this.steps[this.stepCurrent].fields[0].value = el.value
      if (el.value == null) {
        this.steps[this.stepCurrent].fields[1].value = '0,0'
      }
      else {
        this.steps[this.stepCurrent].fields[1].value = el.hidden
      }
    },
    nextStep (evt) {
      evt.preventDefault();

      let form = this.$refs['accountForm']
      if (form.checkValidity() === false) {
        this.form_class = 'was-validated'
      }
      else {
        if (this.stepCurrent < this.steps.length - 1) {
          this.form_class = null
          this.stepCurrent++
        }
        else {
          this.loading = true
          const params = new URLSearchParams();
          params.append('action', 'updateUser2');

          this.steps.map(step => {
            if (step.fields) {
              return step.fields.map(field => {
                params.append(field.name, field.value);
                return [
                  field.name,
                  field.value
                ]
              })[0]
            }
          })
          params.append('uuid', this.$store.getters.getUser);
          axios.post(process.env.VUE_APP_API, params).then(response => {
            if (response.data.errors) {
              this.$store.commit('setAlert', {
                type: 'danger',
                messages: response.data.errors
              })
            }
            else {
              this.loading = false
              this.stepCurrent = 1
              this.$router.push('finder')
              this.$store.commit('setProfile', response.data.data)
            }
          })
        }
      }
    },
    prevStep () {
      this.stepCurrent--
    },
    selectImage () {
      this.$refs.fileInput[0].click()
    },
    pickFile (index) {
      let input = this.$refs.fileInput[0]
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.steps[this.stepCurrent].fields[index].value = e.target.result
        }
        reader.readAsDataURL(file[0])
      }
    },
    getDataUrl (img, callback) {
      axios.get('https://cors-anywhere.herokuapp.com/' + img, {
        responseType: 'blob'
      }).then(response => {
        let reader = new FileReader
        reader.onload = function() {
          var dataUrl = reader.result;
          var base64 = dataUrl
          callback(base64)
        }
        reader.readAsDataURL(response.data)
      })
    }
  },
  watch: {
    stepCurrent () {
      let name = this.step.fields[0].name
      if (this.options[name]) {
        if (this.options[name].length === 0) {
          let requestesName = {
            'follower': 'readFollower',
            'nicchia': 'readNicchie',
            'collaborazione': 'readCollabo'
          }
          axios.get(process.env.VUE_APP_API, {
            params: {
              action: requestesName[name]
            }
          }).then(response => {
            if (response.data.errors) {
              this.$store.commit('setAlert', {
                type: 'danger',
                messages: response.data.errors
              })
            }
            else {
              if (typeof(response.data.data[0]) === 'string') {
                this.options[name] = response.data.data
              }
              else {
                this.options[name] = response.data.data.map(item => item[0]).sort()
              }
              
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  align-items: center;
  display: flex;
  height: 100%;
  transition: all 0.3s ease-in-out;
  width: calc(100vw*10);
  fieldset {
    padding: 0 15px;
    width: 100vw;
    .form-panel {
      margin: 0 auto;
      max-width: 720px;
      width: 100%;
      .form-group-hidden {
        display: none;
      }
    }
  }
  .form-label {
    font-size: 1.5rem;
  }

  .select2-selection--multiple, .select2-selection--single {
    border: 1px solid #ced4da!important;
    min-height: 38px;
    padding: 3px 0.75rem;
    li {
      margin: 2px;
    }
    .select2-search__field {
      display: none;
      height: 30px;
      margin: 0 3px;
    }
    .select2-selection__arrow {
      height: 36px;
    }
  }

  .btn {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0.25rem 2rem;
    &.btn-primary {
      background: $primary-color;
      border: none;
      color: #fff;
    }
    &.btn-link {
      color: $primary-color;
      font-weight: normal;
    }
  }

  .input--image {
    .images {
      clear: both;
      overflow: hidden;
      > div {
        float: left;
        margin-right: 10px;
      }
    }
    input {
      display: none;
    }
  }
  .imagePreviewWrapper {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: relative;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    svg {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      fill: $tertiary-color;
    }
    &.hasImage {
      svg {
        opacity: 0;
      }
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
  }

  .input--type {
    position: relative;
    .invalid-feedback {
      bottom: -20px;
      left: 0;
      position: absolute;
    }
  }

  .input--radio {
    label { vertical-align: middle; }
    span { padding-left: 5px; vertical-align: middle; }
  }

  .v-select {
    background: #fff;
    border-radius: 0.25rem;
    ::v-deep {
      .vs__dropdown-toggle {
        border: 1px solid #ced4da;
        padding: 0.375rem 0.75rem;
        .vs__search {
          margin: 0;
        }
      }
      .vs__selected {
        margin: 0;
      }
      .vs__actions {
        padding: 0;
      }
      .vs__selected-options {
        position: static;
      }
      .vs__dropdown-menu {
        max-height: 210px!important;
      }
    }
  }

  ::v-deep {
    .vue-switcher-theme--default.vue-switcher-color--default div {
      background-color: #99AB68;
    }
    .vue-switcher-theme--default.vue-switcher-color--default div:after {
      background-color: #a6b18b;
    }
    .vue-switcher-theme--default.vue-switcher-color--default.vue-switcher--unchecked div {
      background-color: #aaa;
    }
    .vue-switcher-theme--default.vue-switcher-color--default.vue-switcher--unchecked div:after {
      background-color: #c4c4c4;
    }
  }
}
</style>