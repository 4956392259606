<template>
  <div class="card shadow-sm">
    <button class="fav-btn" @click="toggleFavs(profile)">
      <svg v-if="profile.favs" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z"/></svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 9.229c.234-1.12 1.547-6.229 5.382-6.229 2.22 0 4.618 1.551 4.618 5.003 0 3.907-3.627 8.47-10 12.629-6.373-4.159-10-8.722-10-12.629 0-3.484 2.369-5.005 4.577-5.005 3.923 0 5.145 5.126 5.423 6.231zm-12-1.226c0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-7.962-9.648-9.028-12-3.737-2.338-5.262-12-4.27-12 3.737z"/></svg>
    </button>
    <div class="profile-pic my-4 ratio ratio-1x1">
      <div>
        <div class="ratio-1x1 card-img-top" :style="'background-image:url(' +profile.immagine+ ')'"></div>
      </div>
    </div>
    <div class="card-body">
      <h5 class="card-title">{{profile.nome}} {{profile.cognome}}</h5>
      <h6 class="card-subtitle">
        <a :href="'https://www.instagram.com/' + profile.nickname" target="_blank">@{{profile.nickname}}</a>
      </h6>
    </div>
    <div class="buttons">
      <button type="button" class="btn btn-link" @click="openProfile(profile)">
        Visualizza
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    profile () {
      return this.item
    }
  },
  methods: {
    openProfile(item) {
      this.$emit('openProfile', item);
    },
    toggleFavs(item) {
      this.profile.favs = !this.profile.favs;

      const params = new URLSearchParams();
      params.append('action', 'toggleFavs');
      params.append('uuid', this.$store.getters.getUser);
      params.append('uuidFav', item.uuid);
      axios.post(process.env.VUE_APP_API, params).then(response => {
        if (response.data.errors) {
          this.$store.commit('setAlert', {
            type: 'danger',
            messages: response.data.errors
          })
          this.profile.favs = !this.profile.favs;
        }
      }).catch(error => {
        console.error(error)
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  border: 0;
  height: 100%;
  .fav-btn {
    background: none;
    display: inline-block;
    padding: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: auto;
    svg {
      display: block;
      fill: $primary-color;
      height: 18px;
      width: 18px;
    }
    &:hover {
      svg {
        fill: $background-color;
      }
    }
  }
  .profile-pic {
    background: #e5e5e5;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    width: 50%;
    .card-img-top {
      background-position: center center;
      background-size: cover;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }
  h6 {
    font-weight: normal;
    a {
      color: $primary-color;
    }
  }
  .buttons {
    padding: 1rem 1rem;
  }
  button, a.btn {
    background: $primary-color;
    border: none;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
  }
}
</style>