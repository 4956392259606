<template>
  <div class="profile" aria-hidden="false">
    <div class="modal fade show" tabindex="-1" style="display: block">
      <div class="cnt-close" @click="$emit('close')"></div>
      <div class="modal-dialog modal-fullscreen-sm-down modal-dialog-centered">
        <div class="modal-content shadow-sm">
          <button type="button" @click="$emit('close')" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <header>
            <div class="profile-pic my-4 ratio ratio-1x1">
              <div>
                <div class="ratio-1x1 card-img-top" :style="'background-image:url(' +profile.immagine+ ')'"></div>
              </div>
            </div>
            <h5 class="card-title">{{profile.nome}} {{profile.cognome}}</h5>
          </header>
          <div class="modal-body">
            <dl>
              <dt>Nickname Instagram:</dt>
              <dd><a :href="'https://www.instagram.com/' + profile.nickname" target="_blank">@{{profile.nickname}}</a></dd>
              <dt>Followers:</dt>
              <dd>{{profile.follower}}</dd>
              <dt>Nicchia:</dt>
              <dd>{{profile.nicchia}}</dd>
              <dt>Collaborazione:</dt>
              <dd>{{profile.collaborazione}}</dd>
              <dt>Presentazione:</dt>
              <dd>{{profile.presentazione}}</dd>
            </dl>
            <p class="mastermind" v-if="profile.mastermind">Sono disponibile per il mastermind, scrivimi su Instagram.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profile: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.cnt-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.modal {
  background: rgba($color: ($secondary-color), $alpha: .75);
  .modal-content {
    border: 0;
    .btn-close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .profile-pic {
      background: #e5e5e5;
      border-radius: 50%;
      margin: 0 auto;
      max-width: 150px;
      text-align: center;
      width: 50%;
      .card-img-top {
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }
    }
    header {
      text-align: center;
      h5 {
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
    dl {
      dt {
        margin-top: 15px
      }
    }
    .mastermind {
      color: #99AB68
    }
    a {
      color: $primary-color;
    }
  }
}
</style>