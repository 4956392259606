<template>
  <div id="register-form" class="content">
    <div class="container-fluid">
      <section id="send-reset">
        <div class="form-content">
          <form ref="sendResetForm" class="needs-validation" :class="form_class" novalidate @keyup.enter="submit">
            <h3 class="text-center">Reset password</h3>
            <div class="form-group">
              <label for="email">Nuova Password *</label>
              <input :disabled="isWaiting == true" type="password" minlength="8" name="password" required id="password" v-model="password" class="form-control">
              <div class="invalid-feedback">
                Inserisci una password lunga almeno 8 caratteri.
              </div>
            </div>
            <div class="form-group">
              <label for="email">Ripeti Password *</label>
              <input :disabled="isWaiting == true" type="password" minlength="8" name="password2" required id="password2" v-model="password2" class="form-control">
              <div class="invalid-feedback">
                Inserisci una password lunga almeno 8 caratteri.
              </div>
            </div>
            <div class="form-group button-group">
              <button :disabled="isWaiting == true" class="btn btn-primary" @click="submit">Invia</button>
            </div>
          </form>
        </div>
        <div class="register text-center">
          <p>Sei già registrato? <a @click="$router.push('/')">Clicca qui</a></p>
        </div>
      </section>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

export default {
  data () {
    return {
      form_class: null,
      password: null,
      password2: null,
      isWaiting: false
    }
  },
  methods: {
    submit (evt) {
      evt.preventDefault();

      let form = this.$refs['sendResetForm']
      if (form.checkValidity() === false) {
        this.form_class = 'was-validated'
      }
      else {
        this.isWaiting = true
        if (this.password === this.password2) {
          const params = new URLSearchParams();
          params.append('action', 'resetPassword');
          params.append('password', this.password);
          params.append('token', this.$route.query.token);
          axios.post(process.env.VUE_APP_API,params).then(response => {
            if (response.data.errors) {
              this.$store.commit('setAlert', {
                type: 'danger',
                messages: response.data.errors
              })
            }
            else {
              this.$store.commit('setAlert', {
                type: 'success',
                messages: ['Password resettata con successo.', 'Ora puoi procedere con l\'accesso.']
              })
              this.$router.push('/')
            }
          }).catch(error => {
            console.error(error)
          }).finally(() => {
            this.isWaiting = false
            this.form_class = null
            
            this.email = null
            this.password = null
          })
        }
        else {
          this.$store.commit('setAlert', {
            type: 'danger',
            messages: ['Le password inserite non corrispondono']
          })
        }
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  display: flex;
  align-items: center;
  .container {
    .row {
      h2 {
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

section {
  margin: 0 auto;
  max-width: 400px;
  width: 50%;

  @media screen and (max-width: 991px) {
    width: 90%;
  }
    
  .form-content {
    background: $secondary-color;
    border-radius: 2rem;
    padding: 2rem;
    position: relative;

    h3 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
    .form-group {
      margin-bottom: 1rem;
    }
    input.form-control, textarea.form-control {
      background: $secondary-color;
      border-color: lighten($tertiary-color, 40%);
    }
    .button-group {
      bottom: 0;
      left: 0;
      margin: 0;
      position: absolute;
      text-align: center;
      transform: translateY(50%);
      width: 100%;
      button, a.btn {
        background: $primary-color;
        border: none;
        font-size: 1.25rem;
        font-weight: bold;
        padding: .25rem 2rem;
      }
    }
  }
  .register {
    font-weight: bold;
    margin-top: 3rem;
    a {
      color: $primary-color;
    }
  }
}
</style>