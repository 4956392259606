<template>
  <div class="account">
    <div class="panel">
      <header class="center">
        <logo></logo>
      </header>

      <section>
        <div class="content">
          <account-form></account-form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import AccountForm from '@/components/AccountForm.vue'
import Logo from '@/components/Logo.vue'

export default {
  components: {
    AccountForm,
    Logo
  },
  beforeCreate() {
    let user = this.$store.getters.getUser
    if (!user) {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  height: 100%;
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
  .container {
    .row {
      h2 {
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

.account {
  background: $secondary-color;
  height: 100%;

  

  .panel {
    flex-direction: column;
    display: flex;
    height: 100%;
    position: relative;
    > header {
      padding-top: 2rem;
    }
    > section {
      height: 100%;
      width: 100%;
      .content {
        display: block;
        overflow: hidden;
        width: 100vw;
        
      }
    }
    > footer {
      background: none;
      font-size: 2rem;
      padding-bottom: 1rem;
      text-align: center;
    }
  }
}
</style>